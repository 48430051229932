import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import Widget from "components/Widget/index";

const data = [];
//   { name: "JAN", balance: 400 },
//   { name: "FEB", balance: 150 },
//   { name: "MAR", balance: 400 },
//   { name: "APR", balance: 1000 },
//   { name: "MAY", balance: 400 },
//   { name: "JUN", balance: 1200 },
//   { name: "JUL", balance: 1000 },
//   { name: "AUG", balance: 800 },
//   { name: "SEP", balance: 750 },
//   { name: "OCT", balance: 1500 },
//   { name: "NOV", balance: 1000 },
//   { name: "DEC", balance: 1500 },
// ];

const BalanceHistory = (data) => {
  // this.data = data;
  data = data['data'];
  
  // console.log(data['data']);
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <Widget styleName="gx-card-full">
      <div className="ant-row-flex gx-px-4 gx-pt-4">
        <h2 className="h4 gx-mb-3">Monthly transaction report</h2>
      </div>

      <ResponsiveContainer width="100%" height={180}>
        {/* <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Tooltip />
          <XAxis dataKey="name" />
          <defs>
            <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <Area
            dataKey="balance"
            strokeWidth={2}
            stackId="2"
            stroke="#10316B"
            fill="url(#color15)"
            fillOpacity={1}
          />
        </AreaChart> */}
        <BarChart width={600} height={600} data={data}>
            <Bar dataKey="count" fill="green" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="month" />
            <YAxis />
        </BarChart>
      </ResponsiveContainer>
    </Widget>
  );
};
/* 
const colors = [
  "#38ef7d",
  "#83a4d4",
  "#11998e",
  "#f7797d",
  "#8e44ad",
  "#F8FFAE",
];

const BalanceHistory = ({ data }) => {
  const keys = data[0]
    ? Object.keys(data[0]).filter((val) => val !== "date")
    : [];

  return (
    <Widget styleName="gx-card-full gx-mb-0 ">
      <div className="gx-p-4 ">
        <div className="ant-row-flex">
          <h2 className="h4 gx-mb-4">7 Days Cummulative Usage</h2>
        </div>

        <ResponsiveContainer width="100%" height={210}>
          <AreaChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <Tooltip />
            <XAxis dataKey="date" />
            {keys.length && <YAxis />}
            <defs>
              {keys.length &&
                keys.map((_, i) => (
                  <linearGradient
                    key={i}
                    id={`color-${i}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor={colors[i]} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={colors[i]} stopOpacity={0} />
                  </linearGradient>
                ))}
            </defs>
            {keys.length &&
              keys.map((item, i) => (
                <Area
                  dataKey={item}
                  key={i}
                  type="monotone"
                  strokeWidth={2}
                  stackId="2"
                  stroke={colors[i]}
                  fill={`url(#color-${i})`}
                  fillOpacity={1}
                />
              ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Widget>
  );
};
 */
export default BalanceHistory;
