import React from "react";
import { Col, Row, Select } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  faBolt,
  faCarBattery,
  faTint,
  faFaucet,
  faBurn,
} from "@fortawesome/free-solid-svg-icons";
import { increamentData } from "../../Metrics/data";
import ChartCard from "components/dashboard/Crypto/ChartCard";
import Auxiliary from "util/Auxiliary";
import Portfolio from "components/dashboard/Crypto/Portfolio";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";
import BalanceHistory from "components/dashboard/Crypto/BalanceHistory";
import { get_all_utilities } from "../../../../appRedux/actions/Utility";
import { get_tariff } from "../../../../appRedux/actions/Tariff";
import { individual_block_report } from "../../../../appRedux/actions/Reports";
import moment from "moment";

class Individual extends React.Component {
  state = {
    dailyUsage: [],
    title: [],
    utility: {},
    count: {},
    totalPrice: [],
    individualReports: [],
    weekly: [],
    defaultUtility: "EB",
    selectedUtility: { code: "", name: "" },
  };
  componentDidMount() {
    const projectId = JSON.parse(localStorage.getItem("projectId"));
    this.props.get_tariff();
    axios
      .get(`dashboard/data?projectId=${projectId}&period=Day`)
      .then((res) => {
        var keys = res.data.data[0] ? Object.keys(res.data.data[0]) : [];
        this.setState({
          dailyUsage: res.data.data[0],
          title: keys,
          count: res.data.projectCount,
        });
      })
      .catch((error) => error.response);
    this.props.get_all_utilities();
    var url = `&utility=${this.state.defaultUtility}`;
    this.props.individual_block_report(url);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.utilities && this.props.utilities !== nextProps.utilities) {
      var utility = {};
      nextProps.utilities.map((list, i) => {
        const { name, code } = list;
        if (i == 0) {
          var selectedUtility = { code, name };
          this.setState({ selectedUtility });
        }
        utility[code] = name;
      });
      this.setState({ utility: utility });
      return true;
    } else if (
      this.props.individualBlockDetails !== nextProps.individualBlockDetails
    ) {
      this.setState({ individualReports: nextProps.individualBlockDetails });
      return true;
    } else {
      return true;
    }
  }
  getUnitsAndIcon(utilityType) {
    switch (utilityType) {
      case "EB":
        return { icon: faBolt, unit: "Units" };
      case "DG":
        return { icon: faCarBattery, unit: "Units" };
      case "GM":
        return { icon: faBurn, unit: "Kgs" };
      case "MWM":
        return { icon: faTint, unit: "Litres" };
      case "TWM":
        return { icon: faFaucet, unit: "Litres" };
      default:
        return { icon: "", unit: "" };
    }
  }

  handleMenuChange = (value) => {
    const { utilities } = this.props;
    let selectedUtility = {};
    utilities.forEach((item) => {
      const { name, code } = item;
      if (code === value) {
        selectedUtility = { code, name };
      }
    });
    this.setState({ selectedUtility });
    this.setState({ defaultUtility: selectedUtility.code });
    var url = `&utility=${selectedUtility.code}`;
    this.props.individual_block_report(url);
  };
  renderSelectMenu = () => {
    const { utilities } = this.props;
    const availableUtilities = [];
    utilities.forEach((item, i) => {
      const { name, code } = item;
      if (code != "MTCE") availableUtilities.push({ code, name });
    });

    return (
      <Select
        value={this.state.selectedUtility.name}
        onChange={this.handleMenuChange}
        style={{ width: 150 }}
      >
        {availableUtilities.map((item) => (
          <Select.Option key={item.code} value={item.code}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  };
  render() {
    return (
      <Auxiliary>
        <Row
          style={{
            justifyContent: "flex-end",
            marginBottom: 20,
            marginRight: 5,
          }}
        >
          {this.renderSelectMenu()}
        </Row>
        <Row>
          {this.state.individualReports &&
            this.state.individualReports.map((list, index) => {
              const { icon, unit } = this.getUnitsAndIcon(
                this.state.defaultUtility
              );
              var getLastCount = list.individualBlocks.length - 1;
              return list.individualBlocks[getLastCount].date ==
                moment().format("YYYY-MM-DD") ? (
                <Col xl={8} lg={12} md={12} sm={12} xs={24} key={index}>
                  <ChartCard
                    prize={
                      Math.round(
                        list.individualBlocks[getLastCount].value * 100
                      ) / 100
                    }
                    title={unit}
                    icon={icon || "bitcoin"}
                    children={
                      <ResponsiveContainer width="100%" height={75}>
                        <AreaChart
                          data={list.individualBlocks}
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                          <Tooltip />
                          <defs>
                            <linearGradient
                              id="color4"
                              x1="0"
                              y1="0"
                              x2="1"
                              y2="0"
                            >
                              <stop
                                offset="5%"
                                stopColor="#4ECDE4"
                                stopOpacity={0.9}
                              />
                              <stop
                                offset="95%"
                                stopColor="#06BB8A"
                                stopOpacity={0.9}
                              />
                            </linearGradient>
                          </defs>
                          <Area dataKey="date" />
                          <Area
                            dataKey="value"
                            type="monotone"
                            strokeWidth={0}
                            stackId="2"
                            stroke="#4D95F3"
                            fill="url(#color4)"
                            fillOpacity={1}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    }
                    desc={list.blocks.name}
                  />
                </Col>
              ) : (
                ""
              );
            })}
        </Row>
        <Row className="gx-align-items-stretch">
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <Portfolio
              utilities={this.props.utilities}
              title={this.state.title}
              dailyUsage={this.state.dailyUsage}
              tariffPrice={this.props.tariffPrice}
            />
          </Col>
          <Col xl={12} lg={24} md={12} sm={24} xs={24}>
            <BalanceHistory data={this.state.count.monthlyTransaction} />
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps({ common, utility, tariff, reports }) {
  const { utilities } = utility;
  const { error_occurred, loading, show_message, warning_message } = common;
  const { tariffPrice } = tariff;
  return {
    tariffPrice,
    error_occurred,
    loading,
    show_message,
    warning_message,
    utilities,
    individualBlockDetails: reports.individualBlockReport,
  };
}
export default connect(mapStateToProps, {
  get_all_utilities,
  get_tariff,
  individual_block_report,
})(Individual);
