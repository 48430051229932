import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:
import axios from "axios";
import { AppContainer } from "react-hot-loader";
//"https://smartmeter-app.herokuapp.com/http://52.66.119.84:2025/api/v1";
//"http://localhost:2025/api/v1";

axios.defaults.baseURL = "https://api.ycitysmartmeter.com/api/v1";
//axios.defaults.baseURL = "http://localhost:3000/api/v1";
// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <NextApp />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
